import './app.scss';
import 'simplebar/dist/simplebar.css';
import { inject, PLATFORM, computedFrom } from 'aurelia-framework';
import { CurrencyService } from 'services/currency-service';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { AuthorizeStep } from 'resources/pipelines/authorize-step';
import { MetadataStep } from 'resources/pipelines/metadata-step';
import { ScrollPageStep } from 'resources/pipelines/scroll-page-step';
import { SeoStep } from 'resources/pipelines/seo-step';
import { IntercomStep } from 'resources/pipelines/intercom-step';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IpAddressService } from 'services/ip-address-service';
import { PriceModificationService } from 'services/price-modification-service';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { BlacklistService } from 'services/blacklist-service';
import { SignalRService } from 'services/signalr-service';
import { HealthService } from 'services/health-service';
import { WebsiteService } from 'services/website-service';
import { detectIncognito } from 'detectincognitojs';
import { baseUrl } from 'environment';
import { CapitalizeTextValueConverter } from 'resources/value-converters/capitalize-text';
import { ProductCategoryService } from 'services/product-category-service';
import { ProductService } from 'services/product-service';
import { TooltipOverride } from 'resources/value-converters/tooltip-override';
import { BlogService } from 'services/blog-service';
import SimplebarOverride from 'resources/simplebar_override';
import isOnline from 'is-online';
import { Helper } from 'resources/extensions/helper';
import { ChargebackService } from 'services/chargeback-service';
import { GameService } from 'services/game-service';
import { PreActivateStep } from 'resources/pipelines/preactivate-step';
import { logMiddleware, Store } from 'aurelia-store';
import { TypeExtensions } from 'resources/extensions/type_extensions';
import { cookiesBannerCountries } from 'resources/constants';
import { CartActionType, WebsiteShortCode } from 'resources/helpers/enums';
import { SignalRTrackingService } from 'services/signal-r-tracking-service';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'resources/helpers/enums';
import { AuthenticationHandler } from './resources/extensions/sso_extension';
import NotificationManger, { ToastPosition, NotificationType } from '@chicksgroup/notifications';
import { SubscriptionService } from 'services/subscription-service';
import { SardineAi } from 'resources/helpers/sardine-ai';
import { activationStrategy } from 'aurelia-router';
import { PaymentMethodActions } from 'resources/state/actions';
import { User } from 'resources/helpers/types';
import { HubConnection } from '@microsoft/signalr';
import '@stripe/stripe-js';
import { LanguageWebsiteService } from 'services/language-website-service';
import { PageContentAreaService } from 'services/page-content-area-service';

export const Notifications = new NotificationManger({
    toast: {
        preventDuplicates: true,
        maxAmount: 3,
        timeout: 3000,
        position: ToastPosition.TopRight,
        xMargin: 20,
        yMargin: 40,
        separation: 30
    },
    transformToasts: true,
    snackbar: {
        timeout: 3000
    }
});

@inject(
    CurrencyService,
    SessionService,
    EventAggregator,
    IpAddressService,
    PriceModificationService,
    CurrencyFormatValueConverter,
    BlacklistService,
    SignalRService,
    HealthService,
    WebsiteService,
    CapitalizeTextValueConverter,
    ProductCategoryService,
    ProductService,
    TooltipOverride,
    BlogService,
    Helper,
    ChargebackService,
    GameService,
    Store,
    TypeExtensions,
    SignalRTrackingService,
    ToastService,
    CustomerService,
    SubscriptionService,
    SardineAi,
    LanguageWebsiteService,
    PageContentAreaService
)
export class App extends AuthenticationHandler {
    /**
     * @param {CurrencyService} currencyService
     * @param {SessionService} sessionService
     * @param {EventAggregator} eventAggregator
     * @param {IpAddressService} ipAddressService
     * @param {PriceModificationService} priceModificationService
     * @param {CurrencyFormatValueConverter} currencyFormatValueConverter
     * @param {BlacklistService} blacklistService
     * @param {SignalRService} signalRService
     * @param {HealthService} healthService
     * @param {WebsiteService} websiteService
     * @param {CapitalizeTextValueConverter} capitalizeTextValueConverter
     * @param {ProductCategoryService} productCategoryService
     * @param {ProductService} productService
     * @param {TooltipOverride} tooltipOverride
     * @param {BlogService} blogService
     * @param {Helper} helper
     * @param {ChargebackService} chargebackService
     * @param {GameService} gameService
     * @param {Store} store
     * @param {TypeExtensions} typeExtensions
     * @param {SignalRTrackingService} signalRTrackingService
     * @param {ToastService} toastService
     * @param {CustomerService} customerService
     * @param {SubscriptionService} subscriptionService
     * @param {SardineAi} sardineAi
     * @param {LanguageWebsiteService} languageService
     * @param {PageContentAreaService} pageContentAreaService
     */
    constructor(
        currencyService,
        sessionService,
        eventAggregator,
        ipAddressService,
        priceModificationService,
        currencyFormatValueConverter,
        blacklistService,
        signalRService,
        healthService,
        websiteService,
        capitalizeTextValueConverter,
        productCategoryService,
        productService,
        tooltipOverride,
        blogService,
        helper,
        chargebackService,
        gameService,
        store,
        typeExtensions,
        signalRTrackingService,
        toastService,
        customerService,
        subscriptionService,
        sardineAi,
        languageService,
        pageContentAreaService
    ) {
        super(sessionService, toastService, null);
        this.helper = helper;
        this.currencyService = currencyService;
        this.eventAggregator = eventAggregator;
        this.ipAddressService = ipAddressService;
        this.priceModificationService = priceModificationService;
        this.currencyFormatValueConverter = currencyFormatValueConverter;
        this.blacklistService = blacklistService;
        this.signalRService = signalRService;
        this.healthService = healthService;
        this.websiteService = websiteService;
        this.capitalizeTextValueConverter = capitalizeTextValueConverter;
        this.productCategoryService = productCategoryService;
        this.productService = productService;
        this.tooltipOverride = tooltipOverride;
        this.blogService = blogService;
        this.chargebackService = chargebackService;
        this.gameService = gameService;
        this.store = store;
        this.helper.getResolutions(this);
        typeExtensions.implement(this.helper);
        this.signalRTrackingService = signalRTrackingService;
        this.togglePrevention();
        this.customerService = customerService;
        this.subscriptionService = subscriptionService;
        this.sardineAi = sardineAi;
        eventAggregator.subscribe('toast', (toast) => {
            Notifications._snackbarDetached();
            this.onToastReceived(toast);
        });

        PaymentMethodActions.forEach(action => {
            this.store.registerAction(action.name, action.action);
        });
        this.languageService = languageService;
        this.pageContentAreaService = pageContentAreaService;
    }

    scrollbar;
    urlParams;
    userBlacklist;
    triggeredConnection;
    currencyOptions = [];
    navBarAtTop = true;
    veriffFrame;
    healthTimeout;
    pages;
    triggeredMonitoring;
    triggeredBanner;
    preventScrollEnabled = false;
    preventRefreshEnabled = false;
    languages = [];
    /**
     * @type {User}
     */
    user;
    /**
     * @type {HubConnection}
     */
    connection;
    cart = [];
    activeSnackbar;
    bottomSheet;
    buttonSheetBody;

    async activate() {
        await this.handleSSO();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        await Promise.all([
            this.currencyService.getAllCurrencyRates('CG'),
            this.currencyService.getAllCurrencyRates('CG', false),
            this.currencyService.getActiveCurrenciesByWebsite()
        ]);
        this.loadLanguageFromURL();
    }

    async attached() {
        // Synchronous operations
        this.scrollbar = new SimplebarOverride(document.getElementById('main-page-host'));
        this.handleEventSubscriptions();
        this.handleSignalRConnection();

        // Asynchronous operations
        this.browser = detectIncognito().catch(e => console.error(e));
        this.store.registerMiddleware(logMiddleware, 'after', { logType: 'debug' });

        [this.user, this.pages, this.currencyOptions, this.languages] = await Promise.all([
            this.sessionService.getProfile(),
            this.websiteService.getPagesDictionary(),
            this.sessionService.getCurrencyOptions(),
            this.languageService.getByWebsite()
        ]);

        await this.pageContentAreaService.getSiteStrings(
            this.pages['Site Strings']?.id
        );
        await this.pageContentAreaService.getEnglishSiteStrings(
            this.pages['Site Strings']?.id
        );

        const shouldUpdateCart = sessionStorage.getItem('update_cart');

        if (shouldUpdateCart && !window.location.pathname.includes('cart')) {
            await this.updateCart();
            sessionStorage.removeItem('update_cart');
        } else {
            this.cart = await this.sessionService.getCart();
        }

        if (window.location.pathname === '/') {
            this.preLoadImages();
        }

        if (this.user) {
            await this.sardineAi.handleSardineFlow(this.user.id, this.user.sardineSessionKey, 'onboarding');

            await Promise.all([
                this.chargebackService.userHasChargeback(this.user.id),
                this.blacklistService.getBlacklistByUserEmail(this.user.email).then(blacklist => this.userBlacklist = blacklist)
            ]);
        }

        try {
            // Synchronous operations
            this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            this.handleWindowResizement();
            this.helper.handlePrerender404(this.router);
            this.urlParams = new URLSearchParams(window.location.search);

            // Asynchronous operations
            const ipDataPromise = this.ipAddressService.getIpData();
            const ipPostPromise = this.user ? this.ipAddressService.postIp() : Promise.resolve();

            const [ipData] = await Promise.all([ipDataPromise, ipPostPromise]);

            if (this.urlParams.get('openLivechat') && window.Intercom) {
                window.Intercom('show');
            }

            if (cookiesBannerCountries.includes(ipData?.countryCode) && (!this.user?.acceptedCookies && !localStorage.getItem('accepted_cookies'))) {
                this.eventAggregator.publish('banner-updated', {
                    stateBanner: 'cookies',
                    text: 'We use essential cookies to ensure the functionality of our website.',
                    clickFunctionName: 'acceptCookies',
                    actionLabel: 'Accept Cookies'
                });
                this.helper.handleGtagEvent('default', null, null, null, null, null, false);
            }

            if (this?.user?.acceptedCookies === false && localStorage.getItem('accepted_cookies')) {
                this.user.acceptedCookies = true;
                this.customerService.updateInformation(this.user);
            }

            if (this?.user?.acceptedCookies === true && cookiesBannerCountries.includes(ipData?.countryCode)) {
                this.helper.handleGtagEvent('default', null, null, null, null, null, true);
            }

            this.handleReferralAndReferrerLinks();
            this.handleLinksWithinContent();

            // Defer non-essential tasks
            setTimeout(() => {
                this.handleBreadcrumbList();
                this.handleReviewSchema();
                this.handleScrollBar();
                this.handleTooltipOverride();
                window.prerenderReady = true;
            }, 0);

            // Events & Scripts calls
            this.handleGoogleTagManagerUserId(this.user);
            this.handleMonitoringWebsite();

            if (this.user && this.userBlacklist.some(x => x.level === 3 || x.level === 4)) {
                if (window.Intercom) {
                    window.Intercom('shutdown');
                }
                return;
            }

            this.sessionService.initializeLivechat();
        } catch (e) {
            console.log(e);
        }

        // Favicon on load
        this.setFavicon();
        this.addMaximumScaleToMetaViewport();
        this.checkPendingNotifications(true);

        // Change favicon when user changes color mode
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => this.setFavicon());
    }

    detached() {
        this.helper.disposeAll(this.helper.getVariablesByName(this, ['Subscriber']));
        document.removeEventListener('visbilitychange', this.visibilityChanged);
        if (!this.connection) return;
        this.bottomSheet.removeEventListener('dismissed', this.handleDismiss);
        this.helper.killSignalRListeners(this.connection, [
            'BlacklistResolution',
            'ApiRedeployment',
            'ProductPricesUpdate',
            'Logout',
            'SendDestroyCart'
        ]);
    }

    async loadLanguageFromURL() {
        this.languages = await this.languageService.getByWebsite();

        const hreflangList = this.languages.filter(lang => lang.language.hrefLang).map(lang => lang.language.hrefLang);

        const parsedUrl = new URL(window.location.href);
        const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
        const languageFromURL = pathSegments.length > 0 ? pathSegments[0] : '';

        const currentLanguage = await this.sessionService.getLanguage();
        const languageToSet = languageFromURL && hreflangList.includes(languageFromURL) ?
            this.languages?.find(e => e.language.hrefLang === languageFromURL) :
            this.languages?.find(e => e.language.hrefLang === 'en');
        if (currentLanguage.language.hrefLang !== languageToSet.language.hrefLang) this.sessionService.saveLanguage(languageToSet);
    }

    async updateCart() {
        let oldCart = await this.sessionService.getCart(false, true);
        this.eventAggregator.publish('user-updated', { user: this.user });

        if (oldCart && this.subscriptionService.hasSubscription(this.user)) {
            oldCart = oldCart.filter(x => x.game?.name !== 'Subscription');
        }

        if (oldCart?.length) {
            for (const item of oldCart) {
                await this.sessionService.saveCart(oldCart, true, null, null, null, item, true);
            }
        }

        this.cart = await this.sessionService.getCart(null, true);
        this.eventAggregator.publish('cart-updated', { cart: this.cart });
    }

    togglePrevention() {
        if (this.preventScrollEnabled) {
            document.addEventListener('touchmove', this.preventScroll, { passive: false });
            document.addEventListener('touchstart', this.preventPageRefresh, { passive: false });
        } else {
            document.removeEventListener('touchmove', this.preventScroll);
            document.removeEventListener('touchstart', this.preventPageRefresh);
        }
    }

    preventScroll(event) {
        if (!Array.from(event?.target.classList).includes('top-gap')) return;
        event.preventDefault();
    }

    preventPageRefresh(event) {
        if (event.touches?.length <= 1) return;
        event.preventDefault();
    }

    setFavicon() {
        if (baseUrl().includes('localhost')) return;
        const link = document.querySelector("link[rel='icon']") || document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        if ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || this.browser.isPrivate) {
            link.href = '/favicons/favicon-dark.ico';
        } else {
            link.href = '/favicons/favicon.ico';
        }
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    handleLinksWithinContent() {
        setTimeout(() => {
            const links = Array.from(document.getElementsByTagName('a'));
            links.forEach(link => {
                if (link.href.includes(baseUrl())) {
                    link.removeAttribute('rel');
                }
            });
        }, 500);
    }

    handleEventSubscriptions() {
        this.visibilityChanged = document.addEventListener('visibilitychange', () => {
            this.eventAggregator.publish('visibility-change', document.hidden);
        });

        this.routerSubscriber = this.eventAggregator.subscribe('router:navigation:success', async() => {
            this.handleLinksWithinContent();
            this.handleScrollBar();
            if (!this.firstTimeBreadcrumbCall) {
                this.handleBreadcrumbList();
            }
            if (!document.querySelector('cds-snackbar')) this.interComDeviceStyling();
        });

        this.observeSubscriber = this.eventAggregator.subscribe('observe-element', async payload => {
            this.observing ??= ['#main-page-host'];
            const element = this.observing?.findIndex(x => x === payload.selector);
            if (element === -1) this.observing?.push(payload.selector);
            this.handleWindowResizement();
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async payload => {
            this.user = payload.user;
            this.handleGoogleTagManagerUserId(this.user);
            if (this.user && !payload.noRefresh) {
                await this.sardineAi.handleSardineFlow(this.user.id, this.user.sardineSessionKey, 'login');
                this.ipAddressService.postIp();
                this.handleSignalRConnection();
                this.sessionService.initializeLivechat();
                await this.signalRService.trackUserConnection();
            } else if (payload.logout) {
                this.router.navigateToRoute('home');
            }
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('opened-veriff-dialog', payload => {
            this.hideIntercomBubble(payload.openedVeriffDialog, payload.triggerDeviceStyling);
        });

        this.untrackUserConnectionSubscriber = this.eventAggregator.subscribe('untrack-user', async() => {
            await this.signalRService.untrackUserConnection();
        });

        this.handlePopUpBottomSubscriber = this.eventAggregator.subscribe('bottom-popup', payload => {
            if (this.width > this.desktop) {
                return;
            }
            this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: true, triggerDeviceStyling: true });
            this.bottomSheet.open();
            this.buttonSheetBody.setTooltipSheet(payload);
            this.isBottomSheetOpen = true;
        });

        this.handleScrollInteraction = this.eventAggregator.subscribe('handle-scroll-interaction', payload => {
            this.preventScrollEnabled = payload;
            this.togglePrevention();
        });

        this.eventAggregator.subscribe('size-changed', payload => {
            if (this.isBottomSheetOpen && this.width > this.desktop) this.closeBottomSheet();
            setTimeout(() => {
                if (!this.activeSnackbar) return;
                Notifications._snackbarDetached();
                if (payload.width >= this.desktop) {
                    if (this.activeSnackbar.screenTime === 0) {
                        this.eventAggregator.publish('banner-updated', {
                            stateBanner: this.activeSnackbar.toastType,
                            text: this.activeSnackbar.message,
                            clickFunctionName: this.activeSnackbar.clickHereActive,
                            url: this.activeSnackbar.url,
                            actionLabel: this.activeSnackbar.actionLabel
                        });
                        this.activeSnackbar = null;
                    } else {
                        this.onToastReceived(this.activeSnackbar);
                    }
                }
            }, 100);
        });

        this.eventAggregator.subscribe('close-toast', () => {
            this.activeSnackbar = null;
        });

        this.bottomSheet.addEventListener('dismissed', () => this.closeBottomSheet());
    }

    onToastReceived(toast) {
        this.isdetached = false;
        if (this.width <= this.desktop) {
            toast.toastType = !['success', 'warning', 'info', 'error'].includes(toast.toastType) ? 'info' : toast.toastType;

            setTimeout(() => {
                this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: true, triggerDeviceStyling: true });

                Notifications.add({
                    type: NotificationType.Snackbar,
                    message: toast.message,
                    severity: toast.toastType,
                    extraActionLabel: toast.urlName,
                    onDetached: () => this.openVeriff(this),
                    timeout: toast.screenTime,
                    actionLabel: toast.actionLabel,
                    onAction: () => this.executeLinkAction(this)
                });
                setTimeout(() => {
                    if (toast.screenTime > 0) this.openVeriff();
                }, 3500);
            }, 500);
            this.activeSnackbar = toast;
            return;
        }

        Notifications.add({ type: NotificationType.Toast, title: toast.title, message: toast.message, severity: toast.toastType });
    }

    closeBottomSheet() {
        this.isBottomSheetOpen = false;
        this.bottomSheet.dismiss();
        this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: false, triggerDeviceStyling: true });
    }


    openVeriff = () => {
        this.isdetached = true;
        this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: false, triggerDeviceStyling: true });
        this.activeSnackbar = null;
    };

    executeLinkAction = () => {
        if (this.activeSnackbar?.url) {
            window.open(this.activeSnackbar.url);
        } else {
            this.eventAggregator.publish('toast-action');
        }
        this.activeSnackbar = null;
    };

    async handleSignalRConnection() {
        if (!this.triggeredConnection) {
            this.triggeredConnection = true;
            const startingConnection = await this.signalRService.setupSignalRConnection();
            await this.signalRService.start(startingConnection);
            this.connection = await this.signalRService.getSignalRConnection();

            this.connection.on('BalanceResolution', async(balanceAmount) => {
                const amount = await this.currencyFormatValueConverter.toView(balanceAmount, null, null, false, true);
                const mainElement = document.getElementById('user-balance-main');
                const navBarElement = document.getElementById('balance-tab');
                if (this.user) {
                    this.user.balance = balanceAmount;
                    if (this.sessionService.currentUser) {
                        this.sessionService.currentUser.balance = balanceAmount;
                    }
                }
                if (mainElement) {
                    mainElement.innerHTML = amount;
                }
                if (navBarElement) {
                    navBarElement.childNodes[2].textContent = `Balance ${amount}`;
                    if (navBarElement.childNodes?.[3]) {
                        navBarElement.removeChild(navBarElement.childNodes[3]);
                    }
                }
            });

            this.connection.on('BlacklistResolution', async(blackList) => {
                if (blackList.blackListed) {
                    if (blackList.blackListLevel > 2) {
                        this.sessionService.closeLivechat();
                    }
                    if (blackList.blackListLevel > 3) {
                        await this.sessionService.logout();
                    }
                } else {
                    this.sessionService.initializeLivechat();
                }
            });

            this.connection.on('ApiRedeployment', async(response) => {
                if (response) {
                    this.eventAggregator.publish('banner-updated', { stateBanner: 'warning', text: 'Chicks Gold was updated, please refresh.', clickFunctionName: 'refresh', actionLabel: 'Refresh' });
                }
            });

            this.connection.on('ProductPricesUpdate', async(response) => {
                if (response) {
                    this.eventAggregator.publish('product-prices-updated', { product: response });
                }
            });

            this.connection.on('Logout', this.sessionService.logout);

            if (this.user) {
                await this.signalRService.trackUserConnection();
            }

            this.connection.on('SendCartUpdate', async result => {
                if (this.user.id !== result.userId || result.websiteShortCode !== WebsiteShortCode.ChicksGold) return;
                const cart = await this.sessionService.getCart(true);
                this.eventAggregator.publish('cart-updated', { cart: cart });

                switch (result.cartAction) {
                    case CartActionType.Refresh:
                        if (!cart.length) this.processOrderAndRedirect(result.orderId, CartActionType.Destroy);
                        break;
                    case CartActionType.Destroy:
                        this.processOrderAndRedirect(result.orderId, CartActionType.Destroy);
                        break;
                    case CartActionType.Fulfill:
                        this.processOrderAndRedirect(result.orderId, CartActionType.Fulfill);
                        break;
                    default:
                        break;
                }
            });

            this.connection?.on('ReceiveTracker', async(result) => await this.handleTracker(result));
        }
    }

    async handleTracker(result) {
        const isOnOrder = this.router.currentInstruction.fragment.includes(`/chat/${result.orderId}`);
        await this.checkPendingNotifications(!isOnOrder);
    }

    async checkPendingNotifications(sendNotification = false) {
        if (!this.user) return;
        await this.signalRTrackingService.processSignalRTracking(sendNotification);
        this.eventAggregator.publish('get-unread-notifications');
    }

    async handleReferralAndReferrerLinks() {
        await this.priceModificationService.getPriceModifications();

        const referralLink = this.urlParams.get('ref');
        const platformLink = this.urlParams.get('gclid') ?? this.urlParams.get('msclkid') ?? this.urlParams.get('rdt_cid');

        if (referralLink) {
            this.sessionService.saveReferralLink(referralLink);
            this.urlParams.delete('ref');
        }
        if (platformLink) {
            let platform;
            this.urlParams.has('gclid') ? platform = 'gclid' : this.urlParams.has('rdt_cid') ? platform = 'rdt_cid' : platform = 'msclkid';
            this.sessionService.setPlatformLinkCookie(platform, platformLink, 3);
        }
        const referrerLink = document.referrer;
        if (referrerLink && referrerLink !== '' && !referralLink && !referrerLink.includes('chicksgold') && !referrerLink.includes('localhost') && !referrerLink.includes('user.chicksgroup')) {
            this.sessionService.saveReferrerLink(referrerLink);
        }
    }

    handleWindowResizement() {
        if (window.ResizeObserver) {
            this.observing ??= ['#main-page-host'];
            this.observing = this.observing.filter(x => document.querySelector(x));
            this.resizeObserver?.disconnect();
            this.resizeObserver = new ResizeObserver(async entries => {
                for (const entry of entries) {
                    const width = entry?.contentRect.width;
                    const height = entry?.contentRect.height;
                    const isHost = entry.target.id === 'main-page-host';
                    if (isHost) {
                        this.width = width;
                        this.height = height;
                        this.interComDeviceStyling();
                        this.handleScrollBar();
                        this.handleTooltipOverride();
                    }
                    const payload = { width, height, target: entry.target };
                    let event = 'size-changed';
                    if (!isHost) event += `-${entry.target.id}`;
                    this.eventAggregator.publish(event, payload);
                }
            });
            this.observing.forEach(x => {
                if (x) this.resizeObserver.observe(document.querySelector(x));
            });
        }
    }

    handleGoogleTagManagerUserId(user) {
        const scriptElement = document.getElementById('google-tag-manager');
        if (!scriptElement) {
            const s = document.createElement('script');
            s.setAttribute('id', 'google-tag-manager');
            s.innerHTML = this.getGoogleTagManagerUserIdScript(user);
            document.head.appendChild(s);
        } else {
            scriptElement.innerHTML = this.getGoogleTagManagerUserIdScript(user);
        }
    }

    isRouteAnyPage = (pages) => pages.some(page => this.router?.currentInstruction.config.name.includes(page));

    async handleBreadcrumbList() {
        const paramNames = Object.keys(this.router?.currentInstruction?.params);
        const configName = this.router?.currentInstruction?.config?.name;
        const dynamicPagesForNav = await this.productCategoryService.getAllForNav('CG');
        let foundChildRoute;
        if (configName === 'sell') {
            const sellCategory = this.router.currentInstruction.fragment.split('/');
            foundChildRoute = dynamicPagesForNav.find(x => x.name.toLowerCase() === `${sellCategory[1]} ${sellCategory[2]}`.toLowerCase());
        } else {
            foundChildRoute = dynamicPagesForNav.find(x => x.name.toLowerCase() === this.router?.currentInstruction.config.name.replace('-', ' ').toLowerCase());
        }
        if (foundChildRoute?.gameForNav.length) {
            foundChildRoute = foundChildRoute.gameForNav.find(x => x.slug.find(y => y === window.location.pathname.split('/').pop()));
        }

        if (this.router?.currentInstruction.config.navModel.config.route.includes('/')) {
            this.router.currentInstruction.config.navModel.config.route = this.router?.currentInstruction.config.navModel.config.route.split('/')[0];
        }

        let breadCrumbSchema = `{
                            "@type": "BreadcrumbList",
                            "@id": "${baseUrl()}#/schema/BreadcrumbList/1",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "${this.capitalizeTextValueConverter.toView(configName === 'item' || configName === 'account'
            ? `${configName?.replaceAll('-', ' ')}s`
            : configName === 'blog-post'
                ? 'Blogs'
                : configName?.replaceAll('-', ' '), 'sentence')}",
                                "item": "${`${baseUrl()}${this.router?.currentInstruction.config.navModel.config.route}`}"`;

        if (!['sell', 'blog-post'].some(x => x.includes(configName)) && (paramNames.length === 1 && paramNames[0] !== 'page') || (this.isRouteAnyPage(['items', 'accounts', 'skins']) && configName?.includes('game'))) {
            breadCrumbSchema += `}, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${foundChildRoute?.name ?? this.capitalizeTextValueConverter.toView(Object.values(this.router?.currentInstruction.params)[0].replace(/\d+/g, '').replaceAll('-', ' ').replaceAll('/', ' ').trim(), 'sentence')}",
                                "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment.split('/page')[0]}`}"
                            }]
                        }`;
        } else if (configName === 'blog-post' || (paramNames.length >= 2 && ['item', 'skin', 'account'].some(x => x.includes(configName)) && this.router.currentInstruction.params.id)) {
            let itemObject;
            if (configName === 'blog-post') {
                itemObject = await this.blogService.getBlogBySlug(this.router.currentInstruction.params.blog);
            } else {
                itemObject = await this.productService.getProductById(this.router.currentInstruction.params.id);
            }

            breadCrumbSchema += `}, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${configName === 'blog-post' ? itemObject.blogTag?.name : itemObject.game.name}",
                                "item": "${configName === 'blog-post'
                ? `${baseUrl().slice(0, -1)}/${this.router?.currentInstruction.config.route}/category/${itemObject.blogTag?.name?.toLowerCase()}`
                : `${baseUrl().slice(0, -1)}/${this.router?.currentInstruction.config.route}/${this.router?.currentInstruction.params.game}`}"
                            }, {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "${configName === 'blog-post' ? this.router.currentInstruction.config.navModel.title : itemObject.name}",
                                "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                            }]
                        }`;
        } else if (configName === 'sell') {
            if (this.router.currentInstruction.params.childRoute?.includes('/')) {
                const routeSplit = this.router?.currentInstruction.params.childRoute.split('/');
                const sellCategory = 'sell ';
                const sellMainCategory = dynamicPagesForNav.find(x => x.name.toLowerCase().includes(!this.helper.includesSome(routeSplit[0], ['item', 'account'])
                    ? `${sellCategory}${(routeSplit[0].replaceAll('-', ' '))}`
                    : `${sellCategory}${(routeSplit[0].replaceAll('-', ' ')).slice(0, -1)}`));

                const foundGame = sellMainCategory?.gameForNav.find(x => x.slug.includes(routeSplit[1]));
                const categorySecondRoute = this.router?.currentInstruction.fragment.split('/').slice(0, -1).join('/');
                const mainCategoryWithoutSellWord = sellMainCategory?.name?.replaceAll('Sell ', '');

                breadCrumbSchema += `}, {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "${this.capitalizeTextValueConverter.toView(this.helper.excludeAll(sellMainCategory?.name.toLowerCase(), ['sell currency', 'sell skins', 'sell unique names', 'sell gift cards']) ? `${mainCategoryWithoutSellWord}s` : mainCategoryWithoutSellWord, 'sentence')}",
                                    "item": "${`${baseUrl().slice(0, -1)}${categorySecondRoute}`}"
                                }, {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": "${this.capitalizeTextValueConverter.toView(foundGame?.name, 'sentence')}",
                                    "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                                }]
                            }`;
            } else if (this.router.currentInstruction.params.childRoute) {
                const foundGame = dynamicPagesForNav.find(x => x.name.toLowerCase().includes(!this.helper.includesSome(this.router.currentInstruction.params.childRoute, ['item', 'account'])
                    ? `sell ${this.router.currentInstruction.params.childRoute.replaceAll('-', ' ')}`
                    : `sell ${this.router.currentInstruction.params.childRoute.replaceAll('-', ' ').slice(0, -1)}`));


                breadCrumbSchema += `}, {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "${this.capitalizeTextValueConverter.toView(foundGame?.name?.replaceAll('Sell ', ''), 'sentence')}",
                                    "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                                }]
                            }`;
            }
        } else {
            breadCrumbSchema += '}] }';
        }
        this.helper.combineApplicationLdJsonSchemasIntoOne(breadCrumbSchema, this.router);
        this.firstTimeBreadcrumbCall = false;
    }

    handleReviewSchema() {
        const reviewSchema = `{
                            "@type": "Organization",
                            "@id": "${baseUrl()}#/schema/Organization/ChicksGold",
                            "url": "${baseUrl()}",
                            "logo": {
                                "@id": "${baseUrl()}#/schema/ImageObject/Logo/ChicksGold"
                            },
                            "name": "Chicks Gold Inc.",
                            "telephone": "(1) 347-491-4382",
                            "email": "support@chicksgold.com",
                            "address" : {
                                "@id": "${baseUrl()}#/schema/PostalAddress/ChicksGold"
                            }
                        },
                        {
                            "@type": "PostalAddress",
                            "@id": "${baseUrl()}#/schema/PostalAddress/ChicksGold",
                            "streetAddress": "1 King Street W, Suite 4800",
                            "addressLocality": "Toronto",
                            "addressRegion": "ON",
                            "postalCode": "M5H 1A1",
                            "addressCountry": "CA"
                        },
                        {
                            "@type": "ImageObject",
                            "@id": "${baseUrl()}#/schema/ImageObject/Logo/ChicksGold",
                            "url": "${baseUrl()}static/logo/chicks-logo-large.svg",
                            "contentUrl": "${baseUrl()}static/logo/chicks-logo-large.svg",
                            "width": {
                                "@type": "QuantitativeValue",
                                "value": 512,
                                "unitCode": "E37",
                                "unitText": "pixel"
                            },
                            "height": {
                                "@type": "QuantitativeValue",
                                "value": 512,
                                "unitCode": "E37",
                                "unitText": "pixel"
                            },
                            "caption": "Chicks Gold Logo",
                            "name": "Chicks Gold"
                        }`;
        this.helper.combineApplicationLdJsonSchemasIntoOne(reviewSchema);
    }

    interComDeviceStyling() {
        if (document.querySelector('cds-snackbar') && !this.isdetached) return;
        const intercom = document.getElementById('intercom-style');
        const intercomFacade = document.getElementById('intercom-facade-style');
        const inactiveIntercom = !intercom ? document.getElementById('intercom-facade-btn') : null;
        if (['purchased/chat', 'sold/chat', 'swap/chat'].some(x => this.router.currentInstruction.fragment.includes(x))) {
            this.hideIntercomBubble(true);
            return;
        }
        if ((this.router.currentInstruction.config.route === 'cart' && this.width < this.widescreen) ||
           (this.router.currentInstruction.config.title === 'Product' && this.width < this.phone) ||
           (this.router.currentInstruction.config.name === 'sell' && this.width <= this.tablet) ||
           (this.router.currentInstruction.config.name === 'customer-portal' && this.width <= this.desktop) ||
           (this.helper.includesSome(this.router.currentInstruction.config.name, ['services', 'unique-names']) && !this.helper.isObjectEmpty(this.router.currentInstruction.params) && this.width <= this.desktop)) {
            let bottomSize = '75';
            if (this.router.currentInstruction.config.route === 'cart') {
                bottomSize = '75';
            } else if (this.router.currentInstruction.config.title === 'Product') {
                bottomSize = '80';
            } else if (this.router.currentInstruction.config.name === 'customer-portal') {
                bottomSize = '85';
            }
            const intercomStyling = `.intercom-launcher-frame, .intercom-app > div:nth-child(2) { bottom: ${+bottomSize}px !important; } .intercom-lightweight-app-launcher { bottom: ${+bottomSize}px !important; } .intercom-messenger-frame { bottom: ${+bottomSize + 64}px !important; }`;
            const intercomStyle = this.helper.createOrSelectElement('style#intercom-style', document.head);
            intercomStyle.innerHTML = intercomStyling;

            if (intercomFacade) intercomFacade.remove();

            const intercomCreation = setInterval(() => {
                if (document.getElementById('intercom-facade-btn')) {
                    document.getElementById('intercom-facade-btn').style.bottom = `${+bottomSize}px`;
                    clearInterval(intercomCreation);
                }
            }, 1000);
        } else {
            if (intercom) intercom.remove();
            const intercomFacadeStyling = '#intercom-facade-btn { bottom: 20px !important; }';
            const intercomFacadeStyle = this.helper.createOrSelectElement('style#intercom-facade-style', document.head);
            intercomFacadeStyle.innerHTML = intercomFacadeStyling;
        }
        if (this.router.currentInstruction.fragment === `/${this.getRouteNameForPage('Customer Portal')}/balance`) {
            const intercomBalanceStylingValue = '.intercom-messenger-frame, .intercom-lightweight-app-messenger { max-height: 594px !important; height: calc(100% - 230px) !important; }';
            const intercomBalance = this.helper.createOrSelectElement('style#intercom-balance-style', document.head);
            intercomBalance.innerHTML = intercomBalanceStylingValue;
        }
        if (inactiveIntercom) inactiveIntercom.style.display = 'block';
    }

    hideIntercomBubble = (hideIntercomIcon, triggerDeviceStyling) => {
        let intercom = document.getElementById('intercom-style');
        const inactiveIntercom = !intercom ? document.getElementById('intercom-facade-btn') : null;

        if (inactiveIntercom) inactiveIntercom.style.display = 'none';
        if (hideIntercomIcon) {
            intercom = this.helper.createOrSelectElement('style#intercom-style', document.head);
            intercom.innerHTML = '.intercom-launcher-frame { display: none !important; } .intercom-lightweight-app-launcher { display: none !important; } .intercom-messenger-frame { display: none !important; } #intercom-facade-btn { display: none !important; }';
            document.head.append(intercom);
        } else {
            intercom?.remove();
            if (triggerDeviceStyling) this.interComDeviceStyling();
        }
    };

    handleScrollBar() {
        const navbar = document.getElementById('navigation-bar');
        const scrollbarElement = document.querySelector('#main-page-host > div:last-child');
        if (this.scrollbar) {
            const offset = this.scrollbar.getScrollElement();
            if (this.router.currentInstruction.config.name === 'home') {
                if (scrollbarElement) {
                    scrollbarElement.style.maxHeight = 'none';
                }
                offset.addEventListener('scroll', (e) => {
                    if (this.router.currentInstruction.config.name === 'home') {
                        if (e.target.scrollTop > 0) {
                            navbar.classList.remove('navbar-transparent');
                            this.navBarAtTop = false;
                        } else {
                            navbar.classList.add('navbar-transparent');
                            this.navBarAtTop = true;
                        }
                    }
                });
            }
        }
    }

    getGoogleTagManagerUserIdScript(user) {
        return user
            ?
            `window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                'user_id': '${user.id}',
                'email': '${user.email}'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TZX7NPJ');`
            :
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TZX7NPJ');`;
    }

    handleMonitoringWebsite() {
        this.healthTimeout = setInterval(async() => {
            if (!this.triggeredMonitoring) {
                try {
                    this.triggeredMonitoring = true;
                    const response = await this.healthService.checkApiHealth();
                    if ((await isOnline() && !response)) {
                        this.router.navigateToRoute('site-down');
                        throw new Error('Chicks Gold is currently down. To check status, please open https://status.chicksgold.com/');
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    this.triggeredMonitoring = false;
                }
            }
        }, 60000);
    }


    preLoadImages() {
        const images = ['/home/award.svg', '/home/star_full.svg', '/home/star_half.svg', '/icons/trust-pilot-arrow.svg'];
        for (const img of images) {
            const script = document.createElement('link');
            script.rel = 'preload';
            script.as = 'image';
            script.fetchPriority = 'high';
            script.href = img;
            document.head.append(script);
        }
    }

    handleTooltipOverride() {
        if (this.width < this.desktop && !this.triggeredTooltipOverride) {
            this.triggeredTooltipOverride = true;
            setTimeout(() => {
                document.addEventListener('click', (e) => {
                    if (!e.target.classList.contains('mdc-tooltip__surface') && !e.target.getAttribute('mdc-tooltip')) {
                        const activeTooltips = document.querySelectorAll('.mdc-tooltip--shown:not([role="tooltip"])');
                        activeTooltips.forEach(x => {
                            x.classList.remove('mdc-tooltip--shown', 'mdc-tooltip--showing-transition');
                        });
                    }
                });
                const allTooltips = document.querySelectorAll('[mdc-tooltip]');
                allTooltips.forEach(tooltipElement => {
                    tooltipElement.addEventListener('touchstart', tooltip => {
                        if (!tooltip?.target?.au) return;
                        this.tooltipOverride.toView(tooltip.target.au['mdc-tooltip'].container[74]);
                    });
                });
                const offset = this.scrollbar.getScrollElement();
                offset.addEventListener('scroll', () => {
                    const activeTooltips = document.querySelectorAll('.mdc-tooltip--shown');
                    activeTooltips.forEach(x => {
                        x.classList.remove('mdc-tooltip--shown', 'mdc-tooltip--showing-transition');
                        x.classList.add('mdc-tooltip--hide', 'mdc-tooltip--hide-transition');
                    });
                });
            }, 2000);
        }
    }

    addMaximumScaleToMetaViewport = () => {
        if (!/iPad|iPhone|iPod/.test(navigator.userAgent) || window.MSStream) return;
        const element = document.querySelector('meta[name=viewport]');
        if (!element) return;
        let content = element.getAttribute('content');
        const regex = /maximum\-scale=[0-9\.]+/g;
        content = regex.test(content) ? content.replace(regex, 'maximum-scale=1.0') : [content, 'maximum-scale=1.0'].join(', ');
        element.setAttribute('content', content);
    };

    redirectToHomeIfUserIsInCart() {
        if (this.router.currentInstruction.config.name !== 'cart') return;
        this.router.navigateToRoute('home');
    }

    processOrderAndRedirect = async(orderId, operation) => {
        if (operation === CartActionType.Destroy) this.eventAggregator.publish('order-cancelled', { orderId });
        const operationString = operation === CartActionType.Fulfill ? 'fulfilled' : 'cancelled';
        if (operation !== CartActionType.Destroy) {
            this.redirectToHomeIfUserIsInCart();
        }
        this.toastService.showToast(ToastType.SUCCESS, `Your Order ${orderId} has been ${operationString} successfully`, `Order ${operationString}`);
    };

    async configureRouter(config, router) {
        this.pages = await this.websiteService.getPagesDictionary();
        this.languages = await this.languageService.getByWebsite();

        const hreflangList = this.languages.filter(lang => lang.language?.hrefLang).map(lang => {
            return {
                hreflang: lang.language.hrefLang,
                route: lang.language.hrefLang.split('-').join('').toLowerCase()
            };
        });

        config.options.pushState = true;
        config.title = 'Chicks Gold';
        config.titleSeparator = ' - ';
        config.addAuthorizeStep(AuthorizeStep);
        config.addPreActivateStep(PreActivateStep);
        config.addPreRenderStep(MetadataStep);
        config.addPreRenderStep(SeoStep);
        config.addPostRenderStep(IntercomStep);
        config.addPostRenderStep(ScrollPageStep);
        config.map([
            {
                route: [this.getRouteNameForPage('Home', '')],
                name: 'home',
                moduleId: PLATFORM.moduleName('pages/home/home', 'home'),
                title: this.pages.Home?.title,
                settings: {
                    metaDescription: this.pages.Home?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('About Us'),
                name: 'about-us',
                moduleId: PLATFORM.moduleName('pages/about-us/about-us', 'about-us'),
                title: this.pages['About Us']?.title,
                settings: {
                    metaDescription: this.pages['About Us']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Accounts'),
                name: 'accounts',
                title: this.pages.Accounts?.title,
                moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Accounts?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Accounts')}/page/:page`,
                name: 'accounts-page',
                title: this.pages.Accounts?.title,
                moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Accounts?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Accounts')}/:value`,
                name: 'accounts-game',
                moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                title: 'Accounts',
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Accounts')}/:value/page/:page`,
                name: 'accounts-game-page',
                moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                title: 'Accounts',
                hasProductSchema: true
            },
            {
                route: this.getRouteNameForPage('Currency'),
                name: 'currency',
                moduleId: PLATFORM.moduleName('pages/currency/currency', 'currency'),
                title: this.pages.Currency.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Currency.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Items')}/:value`,
                name: 'items-game',
                moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                title: 'Items',
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Items')}/:value/page/:page`,
                name: 'items-game-page',
                moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                title: 'Items',
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Items')}`,
                name: 'items',
                moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                title: this.pages.Items.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Items.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Items')}/page/:page`,
                name: 'items-page',
                moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                title: this.pages.Items.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Items.metaDescription
                }
            },
            {
                route: [this.getRouteNameForPage('Skins'), this.getRouteNameForPage('More')],
                name: 'skins',
                moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                title: this.pages.Skins?.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Skins?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Skins')}/page/:page`,
                name: 'skins-page',
                moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                title: this.pages.Skins?.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Skins?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Skins')}/:value`,
                name: 'skins-game',
                moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                title: this.pages.Skins?.title,
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Skins')}/:value/page/:page`,
                name: 'skins-game-page',
                moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                title: this.pages.Skins?.title,
                hasProductSchema: true
            },
            {
                route: this.getRouteNameForPage('Gift Cards'),
                name: 'gifts',
                moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                title: this.pages['Gift Cards']?.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages['Gift Cards']?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Gift Cards')}/page/:page`,
                name: 'gifts-page',
                moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                title: this.pages['Gift Cards']?.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages['Gift Cards']?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Gift Cards')}/:value`,
                name: 'gifts-game',
                moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                title: this.pages['Gift Cards']?.title,
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Gift Cards')}/:value/page/:page`,
                name: 'gifts-game-page',
                moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                title: this.pages['Gift Cards']?.title,
                hasProductSchema: true
            },
            {
                route: this.getRouteNameForPage('Swap'),
                name: 'swap',
                moduleId: PLATFORM.moduleName('pages/more/swap/swap', 'swap'),
                title: this.pages.Swap?.title,
                settings: {
                    metaDescription: this.pages.Swap?.metaDescription
                },
                data: undefined,
                activationStrategy: activationStrategy.replace
            },
            {
                route: this.getRouteNameForPage('Unique Names'),
                name: 'unique-names',
                moduleId: PLATFORM.moduleName('pages/more/unique-names/unique-names', 'unique-names'),
                title: this.pages['Unique Names']?.title,
                settings: {
                    metaDescription: this.pages['Unique Names']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Services'),
                name: 'services',
                moduleId: PLATFORM.moduleName('pages/services/services', 'services'),
                title: this.pages.Services?.title,
                hasProductSchema: true,
                settings: {
                    metaDescription: this.pages.Services?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Sell'),
                name: 'sell',
                moduleId: PLATFORM.moduleName('pages/sell/sell', 'sell'),
                title: this.pages.Sell?.title,
                settings: {
                    metaDescription: this.pages.Sell?.metaDescription
                }
            },
            {
                route: 'sellv2',
                name: 'sellv2',
                moduleId: PLATFORM.moduleName('pages/sellv2/sell', 'sellv2'),
                title: 'Sell V2',
                settings: {
                    metaDescription: 'Sell module version 2 (develop)'
                }
            },
            {
                route: ['customer-portal', this.getRouteNameForPage('Customer Portal')],
                name: 'customer-portal',
                moduleId: PLATFORM.moduleName('pages/customer-portal/customer-portal', 'customer-portal'),
                title: this.pages['Customer Portal']?.title,
                settings: {
                    auth: true,
                    metaDescription: this.pages['Customer Portal']?.metaDescription
                }
            },
            {
                route: 'sign-in',
                name: 'sign-in',
                moduleId: PLATFORM.moduleName('pages/auth/auth', 'auth'),
                title: 'Sign In',
                settings: {
                    metaDescription: 'Sign in to Chicks Gold and view your previous RS Gold related orders.'
                }
            },
            {
                route: ['sign-up', 'r/:referralLink'],
                name: 'sign-up',
                moduleId: PLATFORM.moduleName('pages/auth/auth', 'auth'),
                title: 'Sign Up',
                settings: {
                    metaDescription: 'Join the Chicks Gold family! Keep track of your orders, receive special discounts and offers.'
                }
            },
            {
                route: 'sign-in/reset-password',
                name: 'reset-password',
                moduleId: PLATFORM.moduleName('pages/auth/auth', 'auth'),
                title: 'Reset Password',
                settings: {
                    metaDescription: 'Lost your password? No big deal we offer a quick and easy way to reset your password at Chicks Gold.'
                }
            },
            {
                route: this.getRouteNameForPage('Blog'),
                name: 'blog',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs', 'blogs'),
                title: this.pages.Blog?.title,
                settings: {
                    metaDescription: this.pages.Blog?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Blog')}/page/:page`,
                name: 'blog-page',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs', 'blogs'),
                title: this.pages.Blog?.title,
                settings: {
                    metaDescription: this.pages.Blog?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Blog')}/category/:category/page/:page`,
                name: 'blog-category-page',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs', 'blogs'),
                title: this.pages.Blog?.title,
                settings: {
                    metaDescription: this.pages.Blog?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Blog')}/category/:category`,
                name: 'blog-category',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs', 'blogs'),
                title: this.pages.Blog?.title,
                settings: {
                    metaDescription: this.pages.Blog?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Blog')}/:blog`,
                name: 'blog-post',
                moduleId: PLATFORM.moduleName('pages/blogs/blogpost/blogpost', 'blogs'),
                title: this.pages.Blog?.title,
                hasBlogPostSchema: true,
                settings: {
                    metaDescription: this.pages.Blog?.metaDescription
                },
                activationStrategy: activationStrategy.replace
            },
            {
                route: `${this.getRouteNameForPage('Blog')}/author/:alias`,
                name: 'author',
                moduleId: PLATFORM.moduleName('pages/blogs/author/author', 'blogs'),
                title: this.pages.Blog?.title,
                settings: {
                    metaDescription: this.pages.Blog?.metaDescription
                }
            },
            {
                route: 'cart',
                name: 'cart',
                moduleId: PLATFORM.moduleName('pages/cart/cart', 'cart'),
                title: 'Cart',
                settings: { auth: true }
            },
            {
                route: `${this.getRouteNameForPage('Skins')}/:game/:id/:skin`,
                name: 'skin',
                moduleId: PLATFORM.moduleName('pages/product/product', 'skins'),
                title: 'Product',
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Items')}/:game/:id/:item`,
                name: 'item',
                moduleId: PLATFORM.moduleName('pages/product/product', 'items'),
                title: 'Product',
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Accounts')}/:game/:id`,
                name: 'account',
                moduleId: PLATFORM.moduleName('pages/product/product', 'accounts'),
                title: 'Product',
                hasProductSchema: true
            },
            {
                route: `${this.getRouteNameForPage('Gift Cards')}/:game/:id/:giftCard`,
                name: 'gift-card',
                moduleId: PLATFORM.moduleName('pages/product/product', 'gift-cards'),
                title: 'Product',
                hasProductSchema: true
            },
            {
                route: this.getRouteNameForPage('Privacy Policy'),
                name: 'privacy-policy',
                moduleId: PLATFORM.moduleName('pages/privacy-policy/privacy-policy', 'privacy-policy'),
                title: this.pages['Privacy Policy']?.title,
                settings: {
                    metaDescription: this.pages['Privacy Policy'].metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Copyright Policy'),
                name: 'copyright-policy',
                moduleId: PLATFORM.moduleName('pages/copyright-policy/copyright-policy', 'copyright-policy'),
                title: this.pages['Copyright Policy']?.title,
                settings: {
                    metaDescription: this.pages['Copyright Policy'].metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Terms Of Service'),
                name: 'terms-of-service',
                moduleId: PLATFORM.moduleName('pages/terms-of-service/terms-of-service', 'terms-of-service'),
                title: this.pages['Terms Of Service']?.title,
                settings: {
                    metaDescription: this.pages['Terms Of Service']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Bug Bounty'),
                name: 'bug-bounty',
                moduleId: PLATFORM.moduleName('pages/bug-bounty/bug-bounty', 'bug-bounty'),
                title: this.pages['Bug Bounty']?.title,
                settings: {
                    metaDescription: this.pages['Bug Bounty']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Sitemap'),
                name: 'sitemap',
                moduleId: PLATFORM.moduleName('pages/sitemap/sitemap', 'sitemap'),
                title: this.pages.Sitemap?.title,
                settings: {
                    metaDescription: this.pages.Sitemap?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Sitemap')}/:category`,
                name: 'sitemap-category',
                moduleId: PLATFORM.moduleName('pages/sitemap/sitemap', 'sitemap'),
                title: this.pages.Sitemap?.title,
                settings: {
                    metaDescription: this.pages.Sitemap?.metaDescription
                }
            },
            {
                route: `${this.getRouteNameForPage('Sitemap')}/:category/:subcategory`,
                name: 'sitemap-subcategory',
                moduleId: PLATFORM.moduleName('pages/sitemap/sitemap', 'sitemap'),
                title: this.pages.Sitemap?.title,
                settings: {
                    metaDescription: this.pages.Sitemap?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Faq'),
                name: 'faq',
                moduleId: PLATFORM.moduleName('pages/faq/faq', 'faq'),
                title: this.pages.Faq?.title,
                settings: {
                    metaDescription: this.pages.Faq?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Contact Us', 'contact'),
                name: 'contact',
                moduleId: PLATFORM.moduleName('pages/contact/contact', 'contact'),
                title: this.pages['Contact Us']?.title,
                settings: {
                    metaDescription: this.pages['Contact Us']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Coupons'),
                name: 'coupons',
                moduleId: PLATFORM.moduleName('pages/coupons/coupons', 'coupons'),
                title: this.pages.Coupons?.title ?? 'Promotional Codes',
                settings: {
                    metaDescription: this.pages.Coupons?.metaDescription ?? 'Coupon Codes from the #1 Most Trusted Video Game Asset Marketplace. Over 100,000+ coupon codes successfully used!'
                }
            },
            {
                route: this.getRouteNameForPage('Site Down'),
                name: 'site-down',
                moduleId: PLATFORM.moduleName('pages/site-down/site-down', 'site-down'),
                title: this.pages['Site Down']?.title,
                settings: {
                    metaDescription: this.pages['Site Down']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Rate Limit'),
                name: 'rate-limit',
                moduleId: PLATFORM.moduleName('pages/rate-limit/rate-limit', 'rate-limit'),
                title: this.pages['Rate Limit']?.title,
                settings: {
                    metaDescription: this.pages['Rate Limit']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('404'),
                name: '404',
                moduleId: PLATFORM.moduleName('pages/404/404', '404'),
                title: this.pages['404']?.title,
                settings: {
                    metaDescription: this.pages['404']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Expired Link'),
                name: 'expired-link',
                moduleId: PLATFORM.moduleName('pages/expired-link/expired-link', 'expired-link'),
                title: this.pages['Expired Link']?.title,
                settings: {
                    metaDescription: this.pages['Expired Link']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Code of Ethics'),
                name: 'code-of-ethics',
                moduleId: PLATFORM.moduleName('pages/code-of-ethics/code-of-ethics', 'code-of-ethics'),
                title: this.pages['Code of Ethics']?.title,
                settings: {
                    metaDescription: this.pages['Code of Ethics']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Editorial Policy'),
                name: 'editorial-policy',
                moduleId: PLATFORM.moduleName('pages/editorial-policy/editorial-policy', 'editorial-policy'),
                title: this.pages['Editorial Policy']?.title,
                settings: {
                    metaDescription: this.pages['Editorial Policy']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Modern Slavery Statement'),
                name: 'modern-slavery-statement',
                moduleId: PLATFORM.moduleName('pages/modern-slavery-statement/modern-slavery-statement', 'modern-slavery-statement'),
                title: this.pages['Modern Slavery Statement']?.title,
                settings: {
                    metaDescription: this.pages['Modern Slavery Statement']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Accessibility'),
                name: 'accessibility',
                moduleId: PLATFORM.moduleName('pages/accessibility/accessibility', 'accessibility'),
                title: this.pages.Accessibility?.title,
                settings: {
                    metaDescription: this.pages.Accessibility?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Complaints Policy'),
                name: 'complaints-policy',
                moduleId: PLATFORM.moduleName('pages/complaints-policy/complaints-policy', 'complaints-policy'),
                title: this.pages['Complaints Policy']?.title,
                settings: {
                    metaDescription: this.pages['Complaints Policy']?.metaDescription
                }
            },
            {
                route: this.getRouteNameForPage('Disclaimer'),
                name: 'disclaimer',
                moduleId: PLATFORM.moduleName('pages/disclaimer/disclaimer', 'disclaimer'),
                title: this.pages.Disclaimer?.title,
                settings: {
                    metaDescription: this.pages.Disclaimer?.metaDescription
                }
            },
            ...hreflangList.flatMap(hreflang => ([
                {
                    route: [hreflang.hreflang, `${hreflang.hreflang}/${this.getRouteNameForPage('Home', '')}`],
                    name: `home-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/home/home', 'home'),
                    title: this.pages.Home?.title,
                    settings: {
                        metaDescription: this.pages.Home?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('About Us')}`,
                    name: `about-us-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/about-us/about-us', 'about-us'),
                    title: this.pages['About Us']?.title,
                    settings: {
                        metaDescription: this.pages['About Us']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Accounts')}`,
                    name: `accounts-${hreflang.route}`,
                    title: this.pages.Accounts?.title,
                    moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Accounts?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Accounts')}/page/:page`,
                    name: `accounts-page-${hreflang.route}`,
                    title: this.pages.Accounts?.title,
                    moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Accounts?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Accounts')}/:value`,
                    name: `accounts-game-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                    title: 'Accounts',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Accounts')}/:value/page/:page`,
                    name: `accounts-game-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/accounts/accounts', 'accounts'),
                    title: 'Accounts',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Currency')}`,
                    name: `currency-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/currency/currency', 'currency'),
                    title: this.pages.Currency.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Currency.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Items')}/:value`,
                    name: `items-game-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                    title: 'Items',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Items')}/:value/page/:page`,
                    name: `items-game-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                    title: 'Items',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Items')}`,
                    name: `items-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                    title: this.pages.Items.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Items.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Items')}/page/:page`,
                    name: `items-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/items/items', 'items'),
                    title: this.pages.Items.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Items.metaDescription
                    }
                },
                {
                    route: [`${hreflang.hreflang}/${this.getRouteNameForPage('Skins')}`, `${hreflang.hreflang}/${this.getRouteNameForPage('More')}`],
                    name: `skins-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                    title: this.pages.Skins?.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Skins?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Skins')}/page/:page`,
                    name: `skins-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                    title: this.pages.Skins?.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Skins?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Skins')}/:value`,
                    name: `skins-game-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                    title: this.pages.Skins?.title,
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Skins')}/:value/page/:page`,
                    name: `skins-game-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/skins/skins', 'skins'),
                    title: this.pages.Skins?.title,
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Gift Cards')}`,
                    name: `gifts-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                    title: this.pages['Gift Cards']?.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages['Gift Cards']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Gift Cards')}/page/:page`,
                    name: `gifts-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                    title: this.pages['Gift Cards']?.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages['Gift Cards']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Gift Cards')}/:value`,
                    name: `gifts-game-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                    title: this.pages['Gift Cards']?.title,
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Gift Cards')}/:value/page/:page`,
                    name: `gifts-game-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/gift-cards/gift-cards', 'gift-cards'),
                    title: this.pages['Gift Cards']?.title,
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Swap')}`,
                    name: `swap-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/swap/swap', 'swap'),
                    title: this.pages.Swap?.title,
                    settings: {
                        metaDescription: this.pages.Swap?.metaDescription
                    },
                    data: undefined,
                    activationStrategy: activationStrategy.replace
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Unique Names')}`,
                    name: `unique-names-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/more/unique-names/unique-names', 'unique-names'),
                    title: this.pages['Unique Names']?.title,
                    settings: {
                        metaDescription: this.pages['Unique Names']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Services')}`,
                    name: `services-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/services/services', 'services'),
                    title: this.pages.Services?.title,
                    hasProductSchema: true,
                    settings: {
                        metaDescription: this.pages.Services?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Sell')}`,
                    name: `sell-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sell/sell', 'sell'),
                    title: this.pages.Sell?.title,
                    settings: {
                        metaDescription: this.pages.Sell?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/sellv2`,
                    name: `sellv2-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sellv2/sell', 'sellv2'),
                    title: 'Sell V2',
                    settings: {
                        metaDescription: 'Sell module version 2 (develop)'
                    }
                },
                {
                    route: [`${hreflang.hreflang}/customer-portal`, `${hreflang.hreflang}/${this.getRouteNameForPage('Customer Portal')}`],
                    name: `customer-portal-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/customer-portal/customer-portal', 'customer-portal'),
                    title: this.pages['Customer Portal']?.title,
                    settings: {
                        auth: true,
                        metaDescription: this.pages['Customer Portal']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/sign-in`,
                    name: `sign-in-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/auth/auth', 'auth'),
                    title: 'Sign In',
                    settings: {
                        metaDescription: 'Sign in to Chicks Gold and view your previous RS Gold related orders.'
                    }
                },
                {
                    route: [`${hreflang.hreflang}/sign-up`, `${hreflang.hreflang}/r/:referralLink`],
                    name: `sign-up-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/auth/auth', 'auth'),
                    title: 'Sign Up',
                    settings: {
                        metaDescription: 'Join the Chicks Gold family! Keep track of your orders, receive special discounts and offers.'
                    }
                },
                {
                    route: `${hreflang.hreflang}/sign-in/reset-password`,
                    name: `reset-password-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/auth/auth', 'auth'),
                    title: 'Reset Password',
                    settings: {
                        metaDescription: 'Lost your password? No big deal we offer a quick and easy way to reset your password at Chicks Gold.'
                    }
                },
                {
                    route: `${hreflang.hreflang}/cart`,
                    name: `cart-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/cart/cart', 'cart'),
                    title: 'Cart',
                    settings: { auth: true }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Skins')}/:game/:id/:skin`,
                    name: `skin-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/product/product', 'skins'),
                    title: 'Product',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Items')}/:game/:id/:item`,
                    name: `item-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/product/product', 'items'),
                    title: 'Product',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Accounts')}/:game/:id`,
                    name: `account-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/product/product', 'accounts'),
                    title: 'Product',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Gift Cards')}/:game/:id/:giftCard`,
                    name: `gift-card-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/product/product', 'gift-cards'),
                    title: 'Product',
                    hasProductSchema: true
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Privacy Policy')}`,
                    name: `privacy-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/privacy-policy/privacy-policy', 'privacy-policy'),
                    title: this.pages['Privacy Policy']?.title,
                    settings: {
                        metaDescription: this.pages['Privacy Policy'].metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Copyright Policy')}`,
                    name: `copyright-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/copyright-policy/copyright-policy', 'copyright-policy'),
                    title: this.pages['Copyright Policy']?.title,
                    settings: {
                        metaDescription: this.pages['Copyright Policy'].metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Terms Of Service')}`,
                    name: `terms-of-service-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/terms-of-service/terms-of-service', 'terms-of-service'),
                    title: this.pages['Terms Of Service']?.title,
                    settings: {
                        metaDescription: this.pages['Terms Of Service']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Bug Bounty')}`,
                    name: `bug-bounty-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/bug-bounty/bug-bounty', 'bug-bounty'),
                    title: this.pages['Bug Bounty']?.title,
                    settings: {
                        metaDescription: this.pages['Bug Bounty']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Sitemap')}`,
                    name: `sitemap-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sitemap/sitemap', 'sitemap'),
                    title: this.pages.Sitemap?.title,
                    settings: {
                        metaDescription: this.pages.Sitemap?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Sitemap')}/:category`,
                    name: `sitemap-category-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sitemap/sitemap', 'sitemap'),
                    title: this.pages.Sitemap?.title,
                    settings: {
                        metaDescription: this.pages.Sitemap?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Sitemap')}/:category/:subcategory`,
                    name: `sitemap-subcategory-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sitemap/sitemap', 'sitemap'),
                    title: this.pages.Sitemap?.title,
                    settings: {
                        metaDescription: this.pages.Sitemap?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Faq')}`,
                    name: `faq-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/faq/faq', 'faq'),
                    title: this.pages.Faq?.title,
                    settings: {
                        metaDescription: this.pages.Faq?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Contact Us', 'contact')}`,
                    name: `contact-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/contact/contact', 'contact'),
                    title: this.pages['Contact Us']?.title,
                    settings: {
                        metaDescription: this.pages['Contact Us']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Coupons')}`,
                    name: `coupons-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/coupons/coupons', 'coupons'),
                    title: this.pages.Coupons?.title ?? 'Promotional Codes',
                    settings: {
                        metaDescription: this.pages.Coupons?.metaDescription ?? 'Coupon Codes from the #1 Most Trusted Video Game Asset Marketplace. Over 100,000+ coupon codes successfully used!'
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Site Down')}`,
                    name: `site-down-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/site-down/site-down', 'site-down'),
                    title: this.pages['Site Down']?.title,
                    settings: {
                        metaDescription: this.pages['Site Down']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Rate Limit')}`,
                    name: `rate-limit-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/rate-limit/rate-limit', 'rate-limit'),
                    title: this.pages['Rate Limit']?.title,
                    settings: {
                        metaDescription: this.pages['Rate Limit']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Expired Link')}`,
                    name: `expired-link-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/expired-link/expired-link', 'expired-link'),
                    title: this.pages['Expired Link']?.title,
                    settings: {
                        metaDescription: this.pages['Expired Link']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Code of Ethics')}`,
                    name: `code-of-ethics-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/code-of-ethics/code-of-ethics', 'code-of-ethics'),
                    title: this.pages['Code of Ethics']?.title,
                    settings: {
                        metaDescription: this.pages['Code of Ethics']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Editorial Policy')}`,
                    name: `editorial-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/editorial-policy/editorial-policy', 'editorial-policy'),
                    title: this.pages['Editorial Policy']?.title,
                    settings: {
                        metaDescription: this.pages['Editorial Policy']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Modern Slavery Statement')}`,
                    name: `modern-slavery-statement-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/modern-slavery-statement/modern-slavery-statement', 'modern-slavery-statement'),
                    title: this.pages['Modern Slavery Statement']?.title,
                    settings: {
                        metaDescription: this.pages['Modern Slavery Statement']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Accessibility')}`,
                    name: `accessibility-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/accessibility/accessibility', 'accessibility'),
                    title: this.pages.Accessibility?.title,
                    settings: {
                        metaDescription: this.pages.Accessibility?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Complaints Policy')}`,
                    name: `complaints-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/complaints-policy/complaints-policy', 'complaints-policy'),
                    title: this.pages['Complaints Policy']?.title,
                    settings: {
                        metaDescription: this.pages['Complaints Policy']?.metaDescription
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage('Disclaimer')}`,
                    name: `disclaimer-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/disclaimer/disclaimer', 'disclaimer'),
                    title: this.pages.Disclaimer?.title,
                    settings: {
                        metaDescription: this.pages.Disclaimer?.metaDescription
                    }
                }
            ]))
        ]);

        config.mapUnknownRoutes(() => {
            return {
                redirect: `${this.getRouteNameForPage('404')}`
            };
        });

        this.router = router;

        this.router.navigateWithParams = (routeName, params) => {
            const route = this.router.routes.find(x => x.route === routeName);
            route.data = params;
            this.router.navigate(routeName);
        };
    }

    getDataForPage = (name, attribute) => this.pages?.[name]?.[attribute];

    getTitleForPage = (name, def) => this.getDataForPage(name, 'title') ?? def;

    getMetaDescriptionForPage = (name) => this.getDataForPage(name, 'metaDescription');

    getRouteNameForPage = (name, def = null) => this.getDataForPage(name, 'routeName') ?? (def !== null ? def : this.helper.toRoute(name));

    @computedFrom('router.currentInstruction.config.route')
    get isMainPage() {
        return this.helper.excludeAll(this.router?.currentInstruction?.config?.route, [ 'sign-up', 'r/:referralLink', 'sign-in', 'sign-in/reset-password']);
    }
}
