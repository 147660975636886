import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { SessionService } from './session-service';
import { websiteShortCode } from 'environment';
import { IpAddressService } from './ip-address-service';
import { CurrencyService } from './currency-service';
import { Helper } from 'resources/extensions/helper';

// eslint-disable-next-line no-unused-vars
import { IPaymentMethodWebsiteResponse, IRecentPaymentMethodByUserResponse, IGetMultipleThresholdsByIdsResponse } from '@chicksgroup/types';

@inject(ApiService, SessionService, IpAddressService, CurrencyService, Helper)
export class PaymentMethodWebsiteService {
    path = 'PaymentMethodWebsite';
    paymentMethods;
    paymentMethodsForWithdrawal;
    payoutablePaymentMethods;
    thresholds;
    countryShortCode;

    constructor(api, sessionService, ipAddressService, currencyService, helper) {
        this.api = api;
        this.sessionService = sessionService;
        this.ipAddressService = ipAddressService;
        this.currencyService = currencyService;
        this.helper = helper;
    }

    async filter(filter) {
        return await this.api.doGet(this.path + '?filter=' + filter);
    }

    /**
     * Retrieves payment methods by website.
     * @param {boolean} [payoutable] - Indicates whether to include payoutable payment methods.
     * @returns {Promise<IPaymentMethodWebsiteResponse[]>} - A promise that resolves to an array of payment methods.
     */
    async getByWebsite(payoutable = null) {
        if (payoutable) {
            if (this.payoutablePaymentMethods) return this.payoutablePaymentMethods;
            this.payoutablePaymentMethods = await this.api.doGet(`${this.path}/ByWebsite`, { websiteShortCode: websiteShortCode(), countryShortCode: await this.getCountryShortCode(), includePayoutable: true });
            return this.payoutablePaymentMethods;
        } else {
            if (this.paymentMethods) return this.paymentMethods;
            this.paymentMethods = await this.api.doGet(`${this.path}/ByWebsite`, { websiteShortCode: websiteShortCode(), countryShortCode: await this.getCountryShortCode() });
            return this.paymentMethods;
        }
    }

    async getAvailableForWithdrawal() {
        if (this.paymentMethodsForWithdrawal) return this.paymentMethodsForWithdrawal;
        this.paymentMethodsForWithdrawal = await this.api.doGet(`${this.path}/ForWithdrawal`, { websiteShortCode: websiteShortCode(), countryShortCode: await this.getCountryShortCode(), includePayoutable: true });
        return this.paymentMethodsForWithdrawal;
    }

    /**
     * Retrieves the recent payment methods by user ID.
     * If the recent payment methods are already cached, it returns the cached value.
     * Otherwise, it fetches the recent payment methods from the API and caches the result.
     * @returns {Promise<IRecentPaymentMethodByUserResponse[]>} A promise that resolves to an array of recent payment methods.
     */
    async getRecentPaymentMethodByUserId() {
        if (this.recentPaymentMethods) return this.recentPaymentMethods;
        this.recentPaymentMethods = await this.helper.fetchData(this.api, this.path + `/RecentPaymentMethodByUserId?countryShortCode=${await this.getCountryShortCode()}`, 'getRecentPaymentMethodByUserId');
        return this.recentPaymentMethods;
    }

    /*
        VerificationType
        Phone = 0
        ID = 1
        Email = 2
        Address = 3
    */
    /**
     * Retrieves the thresholds for the current website and country.
     * If the thresholds are already cached, it returns the cached value.
     * Otherwise, it makes a POST request to fetch the thresholds and caches the result.
     * @returns {Promise<IGetMultipleThresholdsByIdsResponse[]>} A promise that resolves to the thresholds.
     * @external Promise
     */
    async getThresholds() {
        return this.helper.handlePendingRequest(this, async() => {
            if (this.thresholds) return this.thresholds;
            this.thresholds = await this.api.doPost(this.path + '/GetMultipleThresholdsByIds', {
                websiteShortCode: websiteShortCode(),
                countryShortCode: await this.getCountryShortCode()
            });
            return this.thresholds;
        });
    }

    async getCountryShortCode() {
        if (this.countryShortCode) return this.countryShortCode;
        this.countryShortCode = this.sessionService.getCountry();
        if (!this.countryShortCode) this.countryShortCode = (await this.ipAddressService.getIpData())?.countryCode ?? 'US';
        return this.countryShortCode;
    }

    async getVerificationThresholds(totalSpentByUser, selectedPaymentMethod, totalPrice) {
        if (!this.thresholds) await this.getThresholds();
        const frequencyToAmountMap = {
            0: totalSpentByUser?.totalSpent,
            1: totalSpentByUser?.total1DaySpent,
            7: totalSpentByUser?.total7DaySpent,
            30: totalSpentByUser?.total30DaySpent
        };
        const result = {
            amountRequiresVeriff: false,
            amountRequiresPhoneVeriff: false
        };

        for (const t of this.thresholds) {
            if (t.paymentMethodId !== selectedPaymentMethod?.paymentMethodId) continue;
            const amountSpent = frequencyToAmountMap[t.frequency] || 0;
            const currencyCode = (await this.currencyService.getCurrencyById(t.currencyId))?.code;
            const rate = await this.currencyService.getStoredCurrencyRates(currencyCode);
            const requireVerif = (amountSpent + (totalPrice / rate)) >= t.threshold && totalSpentByUser.totalCountOrderCompleted >= t.transactionThreshold;
            if (t.operationTypeList.excludes('B')) continue;
            t.verificationTypeList.forEach(v => {
                switch (v) {
                    case 1: result.amountRequiresVeriff = requireVerif; break;
                    case 0: result.amountRequiresPhoneVeriff = requireVerif; break;
                }
            });
        }
        return result;
    }
}
