import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/extensions/helper';
import { SessionService } from 'services/session-service';

@inject(ApiService, EventAggregator, Helper, SessionService)
export class PageContentAreaService {
    path = 'PageContentArea';
    retrievedContent = [];
    siteStrings = [];
    englishSiteStrings = [];
    previewsCode = '';
    retrievedContentDict = {};

    constructor(api, eventAggregator, helper, sessionService) {
        this.api = api;
        this.eventAggregator = eventAggregator;
        this.helper = helper;
        this.sessionService = sessionService;
    }

    async getById(id) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getByKey(key) {
        const delimiter = '_';
        const start = 2;

        if (key) {
            const tokens = key.split(delimiter);
            [tokens.slice(0, start), tokens.slice(start)].map((item) => {
                return item.join(delimiter);
            });
        }

        const foundContent = this.retrievedContent.find(x => x.key === key);
        if (foundContent) {
            return foundContent;
        } else {
            return null;
        }
    }

    getImageAltByKey = async(key) => {
        const content = await this.getByKey(key);
        return content?.imageAlt;
    };

    async getByPageId(pageId) {
        const key = `${pageId}_${websiteShortCode()}`;

        if (this.retrievedContentDict[key]) {
            return this.retrievedContentDict[key];
        }

        const currentLanguage = await this.sessionService.getLanguage();

        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode(),
            hrefLang: currentLanguage?.language?.hrefLang || 'en'
        };

        const content = await this.api.doGet(this.path + '/ByPage', params);
        content?.forEach((e) => {
            if (!this.retrievedContentDict[key]) {
                this.retrievedContentDict[key] = [];
            }
            if (!this.retrievedContentDict[key].find(x => x.key === e.key)) {
                this.retrievedContentDict[key].push(e);
            }
        });
        if (this.retrievedContentDict?.[key]) this.retrievedContent.push(...this.retrievedContentDict[key]);
        this.publishContent();
        return this.retrievedContent;
    }

    async getByPageIdAndGame(pageId, gamePage) {
        const currentLanguage = await this.sessionService.getLanguage();
        if (!this.retrievedContent.some(x => x.key?.includes(gamePage)) && pageId && gamePage) {
            const params = {
                pageId: pageId,
                websiteShortCode: websiteShortCode(),
                gamePage: gamePage,
                hrefLang: currentLanguage?.language?.hrefLang || 'en'
            };
            const content = await this.api.doGet(this.path + '/ByPageAndGame', params);
            this.retrievedContent.push(...content);
        }
        this.publishContent();
        return this.retrievedContent;
    }

    publishContent() {
        this.helper.debounce(this, 'sendingContent', 'contentTimeout', 350, () =>
            this.eventAggregator.publish('retrieved-content-changed', this.retrievedContent)
        );
    }

    getSiteStringByKey(key, origin = 'siteStrings') {
        const foundContent = this[origin]?.find(x => x.key === key);
        return foundContent || null;
    }

    async getSiteStrings(pageId) {
        const currentLanguage = await this.sessionService.getLanguage();

        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode(),
            hrefLang: currentLanguage?.hrefLang || 'en'
        };
        this.siteStrings = await this.api.doGet(this.path + '/ByPage', params);

        return this.siteStrings;
    }

    async getEnglishSiteStrings(pageId) {
        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode(),
            hrefLang: 'en'
        };
        this.englishSiteStrings = await this.api.doGet(this.path + '/ByPage', params);

        return this.siteStrings;
    }

    getSiteString = (key, fallback = '') => {
        try {
            const viewingAsAdmin = this.sessionService.getAdminView();

            if (viewingAsAdmin) {
                return `SS-Key: { ${key} }`;
            } else {
                const content = this.getSiteStringByKey(key) ?? this.getSiteStringByKey(key, 'englishSiteStrings');
                return content ? this.helper.sanitizeHtml(content) : fallback;
            }
        } catch (e) {
            console.log(e);
            return key;
        }
    };

    displaySiteString(value, fallback = '', params = []) {
        try {
            const viewingAsAdmin = this.sessionService.getAdminView();

            if (viewingAsAdmin) {
                return `SS-Key: { ${value} }`;
            } else {
                const content = this.getSiteStringByKey(value) ?? this.getSiteStringByKey(value, 'englishSiteStrings');
                const str = content ? this.helper.sanitizeHtml(content.markup, true, ['a', 'br', 'span']) : fallback;
                if (!content && !fallback) return null;
                if (params.length > 0) {
                    return String.raw({
                        raw: str.split('${}')
                    }, ...params);
                }
                return str;
            }
        } catch (e) {
            console.log(e);
            return value;
        }
    }

    reloadLanguages() {
        const retrievedContentKeys = Object.keys(this.retrievedContentDict).map(item => {
            return parseInt(item.split('_')[0]);
        });
        this.retrievedContentDict = {};
        retrievedContentKeys.forEach(k => {
            this.getByPageId(k);
        });
    }
}
